///
/// @file components/prouducts/_mpp_sort.scss
///
/// \brief Styles for mpp sort select
///
///

@import '../../../../scss/theme-bootstrap';

.mpp-sort {
  &__label {
    vertical-align: bottom;
    font-weight: 500;
  }
  &__container {
    display: none;
    @include breakpoint($medium-up) {
      order: 2;
      line-height: 30px;
      padding: 15px 0;
      width: 100%;
      display: flex;
      justify-content: end;
      .select-box__label {
        display: block;
        line-height: 30px;
      }
    }
    &--mobile {
      width: 100%;
      margin: 0 2.5%;
      height: 40px;
      padding: 0;
      display: flex;
      justify-content: center;
      @include breakpoint($medium-up) {
        display: none;
      }
      select {
        appearance: none;
      }
    }
    .mpp-filter-set__item & {
      padding: 0 10px;
    }
  }
  &__arrow {
    height: 15px;
    width: 15px;
    position: absolute;
    #{$rdirection}: 20px;
    top: 10px;
    svg {
      height: 15px;
      width: 15px;
    }
  }
  &-menu {
    width: 100%;
    height: 100%;
    @include breakpoint($medium-up) {
      width: auto;
      height: auto;
    }
  }
  &__select {
    position: relative;
    width: 45%;
  }
}
